<template>
  <div class="main index" v-if="!isLoading" ref="element">
    <div class="columns">
      <div class="column is-10">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <a href>
                <h3 class="is-size-3">INTERVIEW VIDEO</h3>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="column is-2">
        <router-link :to="{ name: `createUploadVideoInterview` }">
          <b-field grouped position="is-right">
            <b-button type="is-hcc">Upload</b-button>
          </b-field>
        </router-link>
      </div>
    </div>
    <div>
      <div class="columns">
        <span class="column is-4">
          <b-field>
            <b-input
              v-model="search"
              placeholder="Search..."
              type="search"
              icon="search"
            ></b-input>
          </b-field>
        </span>
        <span class="column is-8">
          <b-field grouped position="is-right">
            <b-select v-model="perPage" @input="onPageChange(currentPage)">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </b-select>
          </b-field>
        </span>
      </div>
      <div class="columns">
        <div class="column is-12">
          <div class="box">
            <b-table
              :data="
                getInterviewVideos && getInterviewVideos.data
                  ? getInterviewVideos.data
                  : []
              "
              :current-page.sync="currentPage"
              paginated
              backend-pagination
              :total="
                getInterviewVideos && getInterviewVideos.total
                  ? getInterviewVideos.total
                  : 0
              "
              :per-page="perPage"
              @page-change="onPageChange"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              backend-sorting
              :default-sort-direction="defaultSortOrder"
              :default-sort="[sortField, sortOrder]"
              @sort="onSort"
              :loading="isTableLoading"
            >
              <template slot-scope="props">
                <b-table-column field="index" numeric width="20" label="No">
                  <template slot="header" slot-scope="{ column }">
                    <span :label="column.label" class="table-header">{{
                      column.label
                    }}</span>
                  </template>
                  {{ props.index + getInterviewVideos.meta.from }}
                </b-table-column>
                <b-table-column field="page_title" label="Case Study" sortable>
                  <template slot="header" slot-scope="{ column }">
                    <span :label="column.label" class="table-header">{{
                      column.label
                    }}</span>
                  </template>
                  {{ props.row.caseStudy }}
                </b-table-column>
                <b-table-column field="name" label="Upload By" sortable>
                  <template slot="header" slot-scope="{ column }">
                    <span :label="column.label" class="table-header">{{
                      column.label
                    }}</span>
                  </template>
                  {{ props.row.user }}
                </b-table-column>
                <b-table-column
                  field="interview_date"
                  label="Interview Date"
                  sortable
                >
                  <template slot="header" slot-scope="{ column }">
                    <span :label="column.label" class="table-header">{{
                      column.label
                    }}</span>
                  </template>
                  {{ props.row.interviewDate }}
                </b-table-column>
                <b-table-column field="created_at" label="Created At" sortable>
                  <template slot="header" slot-scope="{ column }">
                    <span :label="column.label" class="table-header">{{
                      column.label
                    }}</span>
                  </template>
                  {{ props.row.createdAt }}
                </b-table-column>
              </template>
              <template slot="bottom-left">
                {{
                  getInterviewVideos && getInterviewVideos.meta.from
                    ? getInterviewVideos.meta.from
                    : 0
                }}
                -
                {{
                  getInterviewVideos && getInterviewVideos.meta.to
                    ? getInterviewVideos.meta.to
                    : 0
                }}
                from
                {{
                  getInterviewVideos && getInterviewVideos.meta.total
                    ? getInterviewVideos.meta.total
                    : 0
                }}
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="frown" size="is-large"></b-icon>
                    </p>
                    <p>Data not found.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      isTableLoading: true,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      defaultSortOrder: "desc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: "created_at",
      sortOrder: "asc",
      page: 1,
      search: null
    };
  },
  mounted() {
    this.fetchData(this.perPage, this.currentPage);
  },
  watch: {
    search: function(val) {
      this.searchInterview(val);
    }
  },
  methods: {
    edit(id) {
      this.$router.push({ path: `/interview-schedule/edit/${id}` });
    },
    resend(id) {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el
      });
      this.$store.dispatch("resendEmail", id).then(response => {
        if (response == 400) {
          this.danger("Gagal mengirim ulang email");
        } else {
          this.success("berhasil mengirim email");
        }
        loadingComponent.close();
      });
    },
    deletePopup(id, index) {
      this.$buefy.dialog.confirm({
        title: "Delete Interview Schedule",
        message: `Are you sure want to delete this category?`,
        cancelText: "No, cancel it!",
        confirmText: "Yes, delete it!",
        type: "is-danger",
        onConfirm: () => this.deleteInterviewSchedule(id, index)
      });
    },
    searchInterview(value) {
      if (value) {
        this.search = value;
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        );
      } else {
        this.search = null;
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        );
      }
    },
    async fetchData(perPage, page, sortField, sortOrder, search) {
      this.isTableLoading = true;
      await this.$store.dispatch("getInterviewVideos", {
        perPage,
        page,
        sortField,
        sortOrder,
        search
      });
      this.isTableLoading = false;
    },
    async deleteInterviewSchedule(id, index) {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el
      });
      await this.$store
        .dispatch("deleteInterviewSchedule", { id, index })
        .then(() => {
          this.success("Delete Success");
        })
        .catch(() => {
          this.danger("Cannot delete interview schedule");
        });
      loadingComponent.close();
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.page = page;
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      );
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      );
    }
  },
  computed: {
    ...mapGetters(["getInterviewVideos"])
  }
};
</script>
<style>
.button-action {
  border: none !important;
  color: #00a3a3;
}
</style>
